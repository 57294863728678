<template>
  <div class="static-page page">
    <BreadcrumbsComponent :title="page.title" />
    <div class="container-padding">
      <div class="page__inner">
        <p class="static-page__title">{{ page.subtitle }}</p>
        <div v-if="$options.filters.editor_has_text(page.description)" class="static-page__description">
          <EditorJSComponent :text="page.description" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PAGE from "@/graphql/queries/page.graphql";
import EditorJSComponent from "components/EditorJSComponent.vue";
import BreadcrumbsComponent from "components/BreadcrumbsComponent.vue";

export default {
  name: "StaticPage",
  async asyncData({ apollo, store, route }) {
    await apollo.defaultClient
      .query({
        query: PAGE,
        variables: {
          id: parseInt(route.params.id),
        },
      })
      .then(({ data }) => {
        store.state.static_page = data.page || {};
        // дефолтные данные
        store.state.global.header_categories = data.header_categories;
        store.state.global.footer_categories = data.footer_categories;
        store.state.global.categories = data.categories;
        store.state.global.brands = data.brands;
        store.state.global.info = data.info || {};
        store.state.global.pages = data.pages;
        store.state.global.branches = data.filials;
      });
  },
  computed: {
    page() {
      return this.$store.state.static_page;
    },
  },
  components: {
    EditorJSComponent,
    BreadcrumbsComponent,
  },
  metaInfo() {
    return {
      title: this.page.meta_title || this.page.title,
    };
  },
};
</script>

<style lang="stylus">
.static-page {
  display grid
  padding 32px 15px 48px
  +below(860px) {
    padding 15px 0
  }

  &__container {
    max-width var(--main_width)
    width 100%
    display grid
    align-items start
    gap 24px
    margin 0 auto
  }

  &__title {
    margin 0
  }
}
</style>
